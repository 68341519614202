.masonry {
    display: grid;
    grid-template-columns: repeat(3, 8.25em);
    grid-template-rows: repeat(4, 8.25em);
    gap: 1em;
    padding: 1em;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    background-color: get-color(shades, lightest);
    font-size: 3.333vw;

    .brick {
        &.-product {
            border: 1px solid get-color(shades, light);

            span,
            img {
                grid-area: 1 / 1 / 2 / 2;
            }

            span {
                @include font-stack(secondary, light);

                display: grid;
                justify-content: center;
                align-items: flex-end;
                padding-bottom: $gutter / 2;
                font-size: 1.125em;
                line-height: 1.2;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            img {
                display: block;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                object-fit: contain;
                object-position: center;
            }

            a {
                display: grid;
                width: 100%;
                height: 100%;
                position: relative;
                line-height: 0;
                color: inherit;
            }

            &:hover,
            &:focus-within {
                border-color: get-color(primary);
            }
        }

        &.-bookcases {
            grid-area: 3 / 1 / 4 / 2;
            color: #62a7d1;
        }

        &.-cabinets {
            grid-area: 3 / 2 / 4 / 3;
            color: #1e7497;
        }

        &.-shelving {
            grid-area: 3 / 3 / 4 / 4;
            color: #f4aa00;
        }

        &.-shop-equipment {
            grid-area: 2 / 1 / 3 / 2;
            color: #797e8c;
        }

        &.-lockers {
            grid-area: 2 / 2 / 3 / 3;
            color: #003768;
        }

        &.-filing {
            grid-area: 2 / 3 / 3 / 4;
            color: #c4122f;
        }

        &.-catalog {
            grid-area: 1 / 1 / 2 / 4;
        }

        &.-quick-ship {
            grid-area: 4 / 1 / 5 / 4;
        }

        &.-callout {
            color: get-color(primary, lighter);

            a {
                display: flex;
                grid-gap: $gutter;
                align-items: center;
                height: 100%;
                color: inherit;
            }

            img {
                width: auto;
                height: 100%;
            }

            span {
                @include font-stack(primary, light);
                flex: 1 1 auto;
                font-size: 1.75em;
                text-transform: lowercase;
            }

            &:hover,
            &:focus-within {
                color: get-color(primary);
            }

            &.-quick-ship {
                a {
                    flex-direction: row-reverse;
                    text-align: right;
                }
            }
        }

        &.-promo {
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint(max-width 767px) {
                display: none;
            }
        }
    }

    @include breakpoint(768px) {
        grid-template-columns: repeat(6, 6.667em);
        grid-template-rows: repeat(6, 6.667em);
        font-size: 2.08vw;

        .brick {
            &.-bookcases {
                grid-area: 3 / 1 / 4 / 2;
            }

            &.-cabinets {
                grid-area: 3 / 2 / 4 / 3;
            }

            &.-shelving {
                grid-area: 3 / 3 / 4 / 4;
            }

            &.-catalog {
                grid-area: 1 / 4 / 2 / 7;
            }

            &.-shop-equipment {
                grid-area: 4 / 6 / 5 / 7;
            }

            &.-lockers {
                grid-area: 5 / 6 / 6 / 7;
            }

            &.-filing {
                grid-area: 6 / 6 / 7 / 7;
            }

            &.-quick-ship {
                grid-area: 6 / 1 / 7 / 4;
            }

            &.-callout {
                span {
                    font-size: 2.625em;
                }
            }

            &.-promo {
                &:nth-child(9) {
                    grid-area: 1 / 1 / 3 / 4;
                }

                &:nth-child(10) {
                    grid-area: 2 / 4 / 4 / 7;
                }

                &:nth-child(12) {
                    grid-area: 4 / 1 / 6 / 4;
                }

                &:nth-child(13) {
                    grid-area: 4 / 4 / 7 / 6;
                }

                @include breakpoint(max-width 1024px) {
                    &:nth-child(11),
                    &:nth-child(14) {
                        display: none;
                    }
                }
            }
        }
    }

    @include breakpoint(1025px) {
        grid-template-columns: repeat(8, 11.25em);
        grid-template-rows: repeat(8, 11.25em);
        font-size: 1vw;

        .brick {
            &.-bookcases {
                grid-area: 4 / 2 / 5 / 3;
            }

            &.-cabinets {
                grid-area: 5 / 1 / 6 / 2;
            }

            &.-shelving {
                grid-area: 5 / 2 / 6 / 3;
            }

            &.-catalog {
                grid-area: 1 / 5 / 2 / 9;
            }

            &.-shop-equipment {
                grid-area: 7 / 7 / 8 / 8;
            }

            &.-lockers {
                grid-area: 7 / 8 / 8 / 9;
            }

            &.-filing {
                grid-area: 8 / 7 / 9 / 8;
            }

            &.-quick-ship {
                grid-area: 8 / 1 / 9 / 5;
            }

            &.-promo {
                &:nth-child(9) {
                    grid-area: 1 / 1 / 4 / 5;
                }

                &:nth-child(10) {
                    grid-area: 2 / 5 / 5 / 9;
                }

                &:nth-child(11) {
                    grid-area: 4 / 3 / 6 / 5;
                }

                &:nth-child(12) {
                    grid-area: 6 / 1 / 8 / 5;
                }

                &:nth-child(13) {
                    grid-area: 5 / 5 / 9 / 7;
                }

                &:nth-child(14) {
                    grid-area: 5 / 7 / 7 / 9;
                }
            }
        }
    }
}
