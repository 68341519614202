.site__search {
    display: flex;

    input {
        @include font-stack(primary, light);
        padding: $gutter / 4 $gutter / 2;
        background: none;
        border: none;
        border-bottom: 1px solid get-color(shades, light);
        font-size: 1rem;
        color: get-color(shades, darkest);

        &::placeholder {
            color: get-color(shades, light);
        }

        &:hover,
        &:focus {
            border-bottom-color: get-color(primary);

            + button {
                border-color: get-color(primary);
            }
        }
    }

    button {
        border: none;
        border-bottom: 1px solid get-color(shades, light);
        border-right: 1px solid get-color(shades, light);
        background: none;

        svg {
            width: 1em;
            height: 1em;
            fill: get-color(shades, light);
        }

        &:hover,
        &:focus {
            svg {
                fill: get-color(primary);
            }
        }
    }
}
