.videos {
    display: grid;
    gap: $gutter;
    padding: 0 0 $gutter;
    border-bottom: 1px solid get-color(shades, darkest);
    margin: 0 0 $gutter;
    list-style: none;
    
    @include breakpoint((600px 768px, 1200px)) {
        grid-template-columns: repeat(2, calc(50% - #{$gutter - $gutter / 2}));
    }

    .video {
        &__title {
            font-size: 1.5em;
            font-style: italic;
            font-weight: bold;
            text-align: center;
        }

        &__player {
            width: 100%;
        }

        @supports (aspect-ratio: 16 / 9) {
            &__player {
                aspect-ratio: 16 / 9;
            }
        }
    }
}