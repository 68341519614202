.site__navigation {
    @include font-stack(secondary);
    display: grid;
    grid-template-areas: "search" "navigation";
    grid-template-rows: auto 1fr;
    gap: $gutter;

    .site__search {
        grid-area: search;
    }

    .toggleSubMenu {
        flex: 0 0 auto;
        padding: 0;
        border: 1px solid transparent;
        background: none;
        font-size: 1em;

        &:hover,
        &:focus {
            border-color: get-color(primary);
        }

        svg {
            width: 1em;
            height: 1em;
        }

        &.-open {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    > .navigation__list {
        grid-area: navigation;
    }

    .navigation__link {
        color: get-color(primary);
        white-space: nowrap;
    }

    .navigation__item {
        position: relative;
    }

    .navigation__list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    @include breakpoint(max-width 960px) {
        width: calc(100% - 6rem);
        max-width: 20rem;
        height: 100vh;
        padding: $gutter;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        overflow: auto;
        background: get-color(shades, lightest);
        box-shadow: 0 0 2em rgba(0, 0, 0, 50%);
        
        transition: transform 0.3s;

        &.-closed {
            transform: translateX(-100%);
        }

        .navigation__list {
            flex: 0 0 100%;

            &.-closed {
                display: none;
            }
        }

        .navigation__link {
            font-size: 1rem;

            &:focus {
                text-decoration: underline !important;
            }
        }

        .navigation__item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: $gutter / 4 $gutter / 2;
            background: get-color(shades, lightest);

            &:hover,
            &:focus-within {
                background: get-color(primary, lightest);
            }

            &:not(:last-child) {
                border-bottom: 1px solid get-color(shades, light);
            }

            .navigation__item {
                padding-left: $gutter;
                margin-left: -#{$gutter / 2};
                margin-right: -#{$gutter / 2};

                .navigation__item {
                    padding-left: $gutter * 1.5;
                    margin-left: -#{$gutter};
                }
            }

            .navigation__list {
                margin-bottom: -#{$gutter / 4};
            }
        }
    }

    @include breakpoint(961px) {
        .toggleSubMenu {
            display: none;
        }

        > .navigation__list {
            display: flex;
            gap: $gutter / 2;

            .navigation__list {
                display: none;
                position: absolute;
                z-index: 1;
                background: get-color(shades, lightest);
                box-shadow: 3px 3px 6px hsla(0, 0%, 0%, 0.3);

                .navigation__item {
                    padding: $gutter / 2 $gutter;
                    font-size: 0.875rem;

                    &:hover,
                    &:focus-within {
                        background: get-color(primary, lightest);
                    }
                }
            }

            > .navigation__item {                
                @include breakpoint(1100px) {
                    font-size: 1.3125em;
                }

                > .navigation__link {
                    font-size: 1.167em;
                    text-transform: uppercase;
                    letter-spacing: 0.15em;
                }

                &:not(:last-child)::after {
                    content: "|";
                    margin: 0 0.25rem 0 0.75rem;
                    color: get-color(primary);
                }

                &:hover,
                &:focus-within {
                    > .navigation__link {
                        color: get-color(shades, light);
                    }

                    > .navigation__list {
                        display: block;
                        top: 100%;
                        left: -1rem;

                        > .navigation__item {
                            &:hover,
                            &:focus-within {
                                > .navigation__list {
                                    display: block;
                                    top: 0;
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
