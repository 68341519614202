#ProductsSearch {
    .DataRepeater {
        display: grid;
        gap: $gutter;

        @include breakpoint((480px 677px, 1025px)) {
            grid-template-columns: repeat(2, calc(50% - #{$gutter - $gutter / 2}));
        }

        @include breakpoint((678px 768px, 1201px)) {
            grid-template-columns: repeat(3, calc(33.333% - #{$gutter - $gutter / 3}));
        }
    }

    .Divider {
        display: none;
    }

    .ProductFilters {
        &.-categoryList {
            padding: $gutter;
            background-image: radial-gradient(circle at bottom right, get-color(primary), get-color(primary, lighter));
        }

        .nav-item {
            &-link {
                display: grid;
                align-items: center;
                box-sizing: border-box;
                height: 100%;
                padding: 0.3em;
                border-bottom: 1px solid currentColor;
                border-right: 1px solid currentColor;
                font-size: 1rem;
                color: inherit;

                &:hover,
                &:focus {
                    background: get-color(primary);
                }
            }
        }

        .categoriesNav {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 0;
            border-top: 1px solid currentColor;
            border-left: 1px solid currentColor;
            margin: 0;
            list-style: none;
            color: get-color(shades, lightest);
        }
    }
}

.searchFilters {
    position: relative;

    &__message {
        color: get-color(shades, lightest);
        background: get-color(primary, base);
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        margin: $gutter 0 0;
        padding: 8px;
        text-transform: uppercase;
        cursor: default;

        &::before {
            content: '+';
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 1.2em;
            height: 1.2em;
            border: 1px solid currentColor;
            margin-right: 0.5em;
            line-height: 1;
            text-align: center;
        }

        &.-expanded {
            &::before {
                content: '-';
            }
        }
    }

    .AttributeFilters,
    .AttributeTypeFilters {
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
    }
    
    .AttributeTypeFilters {
        width: calc(100% - 2px);
        border: 1px solid #003768;
        position: absolute;
        overflow: hidden;
        z-index: 1;
        background-color: #d7e9f3;
    
        &.-hidden {
            height: 0;
        }
    }
    
    .AttributeTypeFilter {
        padding: $gutter;
    
        &:not(:last-child) {
            border-bottom: 1px solid #003768;
        }
    }
    
    .AttributeTypeName {
        font-family: Arial, Helvetica, sans-serif;
        background: url(/images/ProductSearch_Expanded.png) no-repeat left center;
        color: #000;
        display: block;
        font-size: 14px;
        padding-left: 15px;
        margin-bottom: 10px;
    
        &.Collapsed {
            background: url("/images/ProductSearch_Collapsed.png") no-repeat left center;
        }
    }
}
