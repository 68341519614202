﻿.StandardNav {
    display: grid;
    gap: $gutter;
    margin: 1rem 0;
    clear: both;

    @include breakpoint((540px 768px ,1025px)) {
        grid-template-columns: repeat(2, calc(50% - #{$gutter / 2}));
    }

    &__Item {
        color: get-color(primary, lighter);

        a {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: $gutter / 2;
            align-items: center;
            color: inherit;
        }

        img {
            width: auto;
            height: 7em;
        }

        span {
            @include font-stack(primary, light);

            flex: 1 1 auto;
            font-size: 1.75em;
            text-transform: lowercase;
        }

        &.-quick-ship {
            a {
                grid-template-columns: 1fr auto;
            }

            img {
                grid-column: 2;
            }

            span { 
                grid-column: 1;
                grid-row: 1;
                text-align: right;
            }
        }
    }
}