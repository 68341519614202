.site__footer {
    @include font-stack(primary, light);
    display: grid;
    grid-template-areas: "logo" "copyright" "address" "legal" "badges";
    gap: $gutter;
    padding: $gutter * 2 $gutter;
    background: get-color(primary);
    color: get-color(shades, lightest);
    clear: both;

    h2 {
        @include font-stack(secondary, medium);
        margin-top: 0;
    }

    a {
        color: currentColor;
    }

    .badge {
        img {
            line-height: 1;
            vertical-align: middle;
        }
    }

    .site__logo {
        grid-area: logo;
    }

    .site__address {
        grid-area: address;

        @include breakpoint(max-width 768px) {
            h2 {
                display: none;
            }
        }
    }

    .badges {
        display: grid;
        grid-area: badges;

        @include breakpoint(max-width 768px) {
            margin-top: $gutter;
            gap: $gutter;
        }
    }

    .copyright {
        grid-area: copyright;

        p {
            margin: 0;
        }
    }

    .legal {
        grid-area: legal;
    }

    @include breakpoint(520px) {
        padding: $gutter * 2 $gutter * 4;
    }

    @include breakpoint(769px) {
        grid-template-columns: repeat(2, 50%);
        grid-template-areas: "logo address" "copyright legal" "badges badges";

        .badges {
            grid-template-columns: repeat(2, 50%);
            align-items: center;
        }
    }

    @include breakpoint(961px) {
        grid-template-columns: repeat(3, 33.333%);
        grid-template-rows: auto auto;
        grid-template-areas: "logo address badges" "copyright legal badges";

        .badges {
            grid-template-columns: auto;
            grid-template-rows: repeat(2, auto);
            align-content: flex-end;
            align-items: flex-end;
        }
    }
}
