.rotator {
    display: block;
    width: 100%;
    height: 75vw;
    position: sticky;
    top: var(--header-height, 0);

    img {
        width: 100%;
        height: 100%;
        line-height: 1;
        object-fit: cover;
    }

    .slide {
        display: inline-flex !important;
        height: 75vw;
    }

    .slick-list {
        line-height: 0;
    }

    .slick-dots {
        display: flex;
        flex-direction: column;
        gap: $gutter / 4;
        padding: 0;
        margin: 0;
        position: absolute;
        top: $gutter * 2;
        right: $gutter * 4;
        list-style: none;

        button {
            width: 1em;
            height: 1em;
            padding: 0;
            border: none;
            border-radius: 50%;
            box-shadow: 0 1px 3px get-color(shades, base);
            overflow: hidden;
            background: currentColor;
            font-size: 0.625rem;
            white-space: nowrap;
            text-indent: 100%;
            color: inherit;
        }

        li {
            color: get-color(shades, lightest);
            transition: color 0.3s;

            &.slick-active {
                color: #c00f33;
            }
        }
    }

    @include breakpoint(768px) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-areas: ". rotator";
        height: 37.5vw;
        background-image: var(--background, url('../images/slider-background.jpg'));
        background-size: contain;
        background-repeat: no-repeat;

        .slide {
            height: 37.5vw;
        }
    
        .slick-list {
            grid-area: rotator;
        }
    }
}
