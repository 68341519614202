﻿#content {
    @include breakpoint(769px) {
        body:not(.page-home) & {
            display: grid;
            grid-template-columns: 18em 1fr;
            // max-width: 96rem;
            background-attachment: fixed;
            background-image: var(--background, url('../images/slider-background.jpg'));
            background-repeat: no-repeat;
            background-size: contain;
        }

        .MainInnerContent {
            grid-column: 2 / 3;
            padding: $gutter $gutter * 3;
        }
    }

    @include breakpoint(961px) {
        body:not(.page-home) & {
            grid-template-columns: 22em 1fr;
        }
    }
}

.MainInnerContent {
    padding: $gutter;
    padding-right: $gutter * 3;
    background: get-color(shades, lightest);

    // body:not(.page-assembly-instructions):not(.page-price-list-brochures):not(.page-videos) & {
    //     > .wrapper {
    //         max-width: 70em;
    //     }
    // }

    h1 {
        @include font-stack(display);

        margin: 0 0 1rem;
        letter-spacing: 0.1em;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        @include font-stack(secondary, medium);

        margin: 0 0 0.25rem;
        line-height: 1.125;
    }

    h2 {
        font-size: 1rem;

        &.underline {
            padding-bottom: $gutter;
            border-bottom-width: 0.3125em;
            // border-bottom-width: 1em;
            border-bottom-style: dotted;
            border-color: get-color(secondary);
            border-image-source: url(../images/dots.svg);
            border-image-slice: 33% 33%;
            border-image-repeat: round;
            margin: $gutter 0;
            font-size: 1.125rem;
        }
    }

    h3 {
        font-size: 0.875rem;
    }

    h4 {
        font-size: 0.75rem;
    }

    h5 {
        font-size: 0.625rem;
    }

    h6 {
        font-size: 0.5em;
    }

    p {
        margin: 0;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .backToTop {
        font-size: 0.5625rem;
    }
}
