ul.resources {
    $gutter: 1rem;

    display: grid;
    gap: $gutter * 3 $gutter;
    padding: 0;
    margin: $gutter 0;
    list-style: none;
    font-size: 1rem;

    @include breakpoint(430px) {
        grid-template-columns: repeat(2, calc(50% - #{$gutter - $gutter / 2}));
    }

    @include breakpoint((640px 768px, 960px)) {
        grid-template-columns: repeat(3, calc(33.333% - #{$gutter - $gutter / 3}));
    }

    @include breakpoint(1200px) {
        grid-template-columns: repeat(4, calc(25% - #{$gutter - $gutter / 4}));
    }

    &.-videos {
        margin-bottom: $gutter * 2;

        @include breakpoint(430px 539px) {
            grid-template-columns: 100%;
        }

        @include breakpoint((640px 768px, 960px)) {
            grid-template-columns: repeat(2, calc(50% - #{$gutter - $gutter / 2}));
        }

        @include breakpoint(1200px) {
            grid-template-columns: repeat(3, calc(33.333% - #{$gutter - $gutter / 3}));
        }
    }

    .video {
        text-align: center;

        &__title {
            font-weight: bold;
        }

        &__frame {
            width: 100%;
            max-width: 310px;
            margin: 0 auto;
            position: relative;

            @supports (aspect-ratio: 16 / 9) {
                aspect-ratio: 16 / 9;
            }

            @supports not (aspect-ratio: 16 / 9) {
                height: 0;
                padding-bottom: 56.25%;

                @include breakpoint((391px 539px, 717px 768px, 1013px 1199px, 1340px)) {
                    padding-bottom: calc(310px * (9 / 16));
                }
            }

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    .resource {
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
        }

        a {
            display: grid;
            gap: $gutter;
            align-items: center;
            grid-template-rows: 160px 1fr;
        }

        &__name {
            font-size: 0.875em;
        }
    }
}