body {
    @include font-stack(primary);

    background: get-color(shades, lightest);
}

#site {
    display: grid;
    min-height: 100vh;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header" "main" "footer";
}

.site__header {
    grid-area: header;
}

.site__footer {
    grid-area: footer;
}

#content,
.MainInnerContainer {
    grid-area: main;
}