.page-contact {
    .LeftUserContent {
        p {
            padding: $gutter;
            margin: $gutter 0 0;
            background-color: get-color(primary);
            color: get-color(shades, lightest);
        }
    }

    .sidebarList {
        display: grid;
        gap: $gutter;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, calc(50% - #{$gutter - $gutter / 2}));
        grid-template-rows: repeat(4, auto);
        padding: $gutter;
        border: 1px solid get-color(primary);
        margin: 0 0 $gutter;
        background-color: get-color(primary, lightest);

        li {
            &:not(:last-child) {
                &::after {
                    display: none;
                }
            }
        }
    }
}