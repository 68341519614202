#ProductPadding {
    max-width: 70em;
}

#ProductInfo {
    display: grid;
    gap: 0 $gutter;
    
    @include breakpoint((670px 768px, 1440px)) {
        grid-template-columns: 27.5em 1fr;
    }

    .Header {
        @include font-stack(display);

        grid-column: 1 / -1;
        letter-spacing: 0.1em;
    }

    #FullImages {
        height: 27.5em;
        border: 1px solid get-color(shades, base);

        .Selected {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    #FindADealer {
        margin: $gutter auto;
    }

    #DownloadProductImage {
        display: inline-block;
        width: 13.667em;
        box-sizing: border-box;
        padding: 0.5em 0.5em 0.5em 4.167em;
        border: 1px solid #eee;
        border-radius: 4px;
        margin: 0.167em;
        background: transparent url("../images/Product_ViewDownload.png") no-repeat 7px center;
        font-size: 1em;
        font-weight: bold;
        line-height: 1.3em;
        text-align: left;
        color: get-color(primary);
    }
}

#ProductContainer {
    #ProductTabs {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 0 1.5em;
        margin: 0;
        list-style: none;
        font-size: 1rem;
    
        .Tab {
            @include font-stack(secondary);

            width: 8.857em;
            height: 2em;
            padding: 0.5em 0 0 1.429em;
            background: url("../images/Product_Tab.png") no-repeat;
            font-size: 0.875em;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
    
            &.Selected {
                background-image: url("../images/Product_TabActive.png");
                color: get-color(shades, lightest);
            }
        }

        @include breakpoint(1200px 1490px) {
            font-size: 1vw;
        }
    }
    
    #TabContainers {
        min-height: 29.125em;
        position: relative;
        font-size: 1rem;
    
        @include breakpoint(1200px) {            
            &::before,
            &::after {
                content: '';
                width: 2.5em;
                height: 29.125em;
                position: absolute;
                top: 0;
            }
        
            &::before {
                background: url(../images/Product_TabLeftBG.png) no-repeat;
                left: -2.5em;
            }
        
            &::after {
                background: url(../images/Product_TabRightBG.png) no-repeat;
                right: -2.5em;
            }
        }

        .Tab {
            @include font-stack(secondary);

            padding: 0.875em 1.429em;
            background: #ffd87c;
            list-style: none;
            font-size: 0.875em;
            font-weight: bold;
            text-transform: uppercase;
            box-shadow: inset 0.5em -0.2em 1.7em rgb(0 0 0 / 25%);

            &.Selected {
                background: get-color(primary);
                color: get-color(shades, lightest);
            }
        }

        .TabContainer {
            padding: 1em;
            
            &:not(.Selected) {
                display: none;
            }
            
            @include breakpoint(1200px) {
                padding: 1em 2em 2em;
                border-top: 1em solid get-color(primary);
            }
        }
    }

    #ProductSpecification {
        padding: 0;
        margin: 0;
        list-style: none;

        br {
            display: none;
        }

        li {
            display: grid;
            gap: $gutter;
            padding: 0.5em;
            border-bottom: 1px solid get-color(shades);
            font-size: 0.75em;
            
            @include breakpoint((480px, 769px 1023px)) {
                grid-template-columns: 15em 1fr;
            }
        }

        .AttributeTypeName {
            font-weight: bold;
        }
    }

    .Disclaimer {
        font-size: 0.875em;
        font-style: italic;
    }

    .ColorsList {
        display: grid;
        gap: $gutter;
        grid-template-columns: repeat(2, calc(50% - #{$gutter - $gutter / 2}));
        padding: 0;
        margin: $gutter 0;
        list-style: none;

        @include breakpoint((420px 539px, 920px)) {
            grid-template-columns: repeat(3, calc(33.333% - #{$gutter - $gutter / 3}));
        }

        @include breakpoint((540px 768px, 1024px)) {
            grid-template-columns: repeat(4, calc(25% - #{$gutter - $gutter / 4}));
        }
    }

    .Color {
        display: block;
        width: 4.25em;
        height: 4.25em;
        margin: 0 auto $gutter / 2;
        border-radius: 50%;

        &Item .Name {
            display: block;
            font-size: 0.75em;
            text-align: center;
            color: get-color(shades, darker);
        }
    }
}    
