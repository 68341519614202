.site__header {
    position: sticky;
    top: 0;
    z-index: 10;

    &__container {
        display: grid;
        grid-template-areas: "logo . navigation";
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto;
        grid-gap: $gutter 0;
        padding: $gutter * 3 $gutter * 3 $gutter $gutter;
        position: relative;
        z-index: 1;
        background-color: get-color(shades, lightest);

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 2;
            background: url('../images/corner-flair.png') 0 0 no-repeat;
        }
    
        &::before {
            content: "";
            width: 19.25em;
            height: 2.917em;
            right: 2.833em;
        }
    
        &::after {
            content: "";
            width: 2.833em;
            height: 52.833em;
            right: 0;
            background-position: 100% 0;
        }
    }

    .site__logo {
        grid-area: logo;
    }

    .site__navigation {
        grid-area: navigation;
        align-self: flex-end;
    }

    .navigation__button {
        grid-area: navigation;
        padding: 0;
        border: none;
        background: none;
        font-size: 3rem;
        color: get-color(primary);

        svg {
            width: 1em;
            height: 1em;
            fill: currentColor;
        }

        &.-hidden {
            display: none;
        }
    }

    @include breakpoint(961px) {
        &__container {
            padding: $gutter $gutter * 4;
        }

        .navigation__button {
            display: none;
        }
    }
}
