.banner {
    &__message {
        padding: $gutter;
        padding-right: $gutter * 2;

        h2 {
            margin-top: 0;
        }

        p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include breakpoint(961px) {
            padding: $gutter $gutter * 4;
        }
    }

    &.-warning {
        background: #ffd87c;
        color: get-color(primary);

        h2 {
            font-size: 1em;
            text-transform: uppercase;
        }
    }

    &.-patriotic {
        background-color: #34365b;
        background-image: linear-gradient(to right, #34365b 0, #34365b 9rem, rgba(#34365b, 0)), url('../images/patriotic-background-mobile.jpg');
        background-repeat: no-repeat;
        background-position: center left, 8rem 50%;
        background-size: 18rem 100%, auto 100%;
        font-size: 1rem;

        p {
            @include font-stack(display);

            width: 9rem;
            margin: 0;
            font-size: 1em;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: get-color(shades, lightest);
        }

        @include breakpoint(540px) {
            background-image: linear-gradient(to right, #34365b 0, #34365b 15rem, rgba(#34365b, 0)), url('../images/patriotic-background.jpg');
            background-position: center left, center right;
            background-size: 18rem 100%, calc(100% - 11rem) 100%;

            p {
                width: 100%;
            }
        }

        @include breakpoint(769px) {
            background-image: linear-gradient(to right, #34365b 0, #34365b 21rem, rgba(#34365b, 0)), url('../images/patriotic-background.jpg');
            background-size: 24rem 100%, calc(100% - 20rem) 100%;
            font-size: 1.25rem;
        }
    }
}
